<template>
  <div>

    <div class="bannerCls">
      <div  class="bannerCls_one">
        <img src="../../assets/contactUsimg/slogan.png" alt="">
      </div>
    </div>

    <div class="intelligentCreation">
      <div class="jiesao">
        <div class="jiesao_title">金烽智造</div>
        <div class="jiesao_content">
          金烽智造是一家工业软件产品提供商，主要面向工业制造领域提供工业软件解决方案，核心产品包括生产制造MES系统、质量溯源等产品。
          金烽智造拥有一支由国防科大博士领衔的高技术研发团队，团队将传统的软件信息技术+人工智能技术应用在工业制造产业的生产运营管理过程中，帮助企业降本增效，实现智能制造转型升级。
          团队通过多年自主研发，目前已形成一批自主核心知识产权，金烽智造作为国家级湘江新区粉末成型应用产业集群提供数字化转型技术支撑单位，为集群企业提供创新，高质的解决方案。
        </div>
        <!-- <img src="../../assets/contactUsimg/组1(1).png" alt=""> -->
      </div>
    </div>

    <div class="honorCls">
      <div>
        <div class="honorCls_one">企业荣誉</div>
        <div class="honorCls_two">
          <!-- <img src="../../assets/contactUsimg/组1.png" alt="" class="honorCls_img">
          <img src="../../assets/contactUsimg/组2.png" alt="" class="honorCls_img">
          <img src="../../assets/contactUsimg/组3.png" alt="" class="honorCls_img"> -->
          <img src="../../assets/contactUsimg/组4.png" alt="" class="honorCls_img_one">
          <img src="../../assets/contactUsimg/组5.png" alt="" class="honorCls_img_one">
        </div>
      </div>
    </div>



    <div class="aptitude">
      <div>
        <div  class="aptitude_one">多重执业合作资质</div>
        <div  class="aptitude_two">
          <img src="../../assets/contactUsimg/金烽数据魔方软件V1.0.png" alt="" class="aptitude_mar_right aptitude_img_size" >
          <img src="../../assets/contactUsimg/金烽客流分析系统V1.O(1).png" alt="" class="aptitude_mar_right aptitude_img_size">
          <img src="../../assets/contactUsimg/金烽车辆管理平台.png" alt="" class="aptitude_mar_right aptitude_img_size">
          <img src="../../assets/contactUsimg/金烽现场技术支援系统.png" alt="" class="aptitude_mar_right aptitude_img_size">
          <img src="../../assets/contactUsimg/金烽客流分析系统V1.O(1).png" alt="" class="aptitude_img_size">
        </div>
        <div>
          <img src="../../assets/contactUsimg/金烽数据魔方软件V1.0(1).png" alt="" class="aptitude_mar_right aptitude_img_size">
          <img src="../../assets/contactUsimg/金烽客流分析系统V1.O(3).png" alt="" class="aptitude_mar_right aptitude_img_size">
          <img src="../../assets/contactUsimg/金烽车辆管理平台(1).png" alt="" class="aptitude_mar_right aptitude_img_size">
          <img src="../../assets/contactUsimg/金烽现场技术支援系统(1).png" alt="" class="aptitude_mar_right aptitude_img_size">
          <img src="../../assets/contactUsimg/金烽现场技术支援系统(1).png" alt="" class="aptitude_img_size">
        </div>
      </div>
    </div>
    <div class="replenishment">
      <div class="from-location">
        <img src="../../assets/AboutUsimg/twinimgthirteen.png" width="100%">
        <div class="frombuu" id="contactUs" ref="contactUs">
          <h4>100＋专业团队为您服务</h4>
          <table action="">
            <div class="mag-lef">
              <div class="unify">
                <font color="red" style="margin-right:5px;font-size:15px">*</font>
                <span>公司名称:</span><br>
                <input type="text" class="inp" placeholder="请输入您所在的公司的名称" name="company" v-model="company">
              </div>
              <!-- <div class="unify">
                <span>所在城市:</span><br>
                <input type="text"  class="inp" placeholder ="请输入您所在城市" name="city" v-model="city">
          </div> -->
              <div class="unify">
                <span>所属行业:</span><br>
                <input type="text" class="inp" placeholder="请输入您所属行业" name="industry" v-model="industry">
              </div>
              <div class="unify">
                <font color="red" style="margin-right:5px;font-size:15px">*</font>
                <span>姓名:</span><br>
                <input type="text" class="inp" placeholder="怎么称呼您" name="name" v-model="name">
              </div>
              <div class="unify">
                <font color="red" style="margin-right:5px;font-size:15px">*</font>
                <span>联系电话:</span><br>
                <input type="text" class="inp" placeholder="我们会及时跟您取得联系~" name="phone" v-model="phone">
              </div>

              <!-- <div class="unify">
            <font color="red" style="margin-right:5px;font-size:15px">*</font>
            <span>我想要</span><br>
            <div class="multiple" >
              <input type="radio" value="数字化咨询"  name="interst" v-model="checkedNames" >数字化咨询
            <input type="radio"  name="interst" value="数据中台解决方案" v-model="checkedNames">数据中台解决方案
            <input type="radio"  name="interst" value="数字孪生" v-model="checkedNames">数字孪生
              <input type="radio"  name="interst" value="成为合作伙伴" v-model="checkedNames">成为合作伙伴
              </div>
          </div> -->

              <div class="unify">
                <span>我的需求</span><br>
                <textarea placeholder="您可以畅所欲言，我们都会一一解答" v-model="demand">
            </textarea>
              </div>

              <p>我们会尽快与您取得联系，请耐心等待~</p>

              <button class="unify" style="cursor:pointer;" @click="getFromList">确认提交</button>
            </div>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { saveAs } from 'file-saver'
import axios from 'axios'
// import nodemailer from 'nodemailer'
import { saveList } from '../../api/communal'
export default {
  data() {
    return {
      UserList: [],
      company: '',
      city: '',
      industry: '',
      name: '',
      phone: '',
      demand: '',
      one: '',
      checkedNames: '数字化咨询'
    }
  },
  mounted() {
    this.scrollToTarget()
  },
  methods: {
    getFromList() {
      if (this.company !== '' && this.name !== '' && this.phone !== '') {
        axios.get('/Finance/Clue!saveOfficialWebsiteClues.action', {
          params: {
           company: this.company,
          industry: this.industry,
          name: this.name,
          telephone: this.phone,
          other: this.demand
         }

        }).then(res => {
          if (res.status=== 200) {
            this.$message({
              message: '提交成功!',
              type: 'success'
            });
          }
          this.company = ''
          this.city = ''
          this.industry = ''
          this.name = ''
          this.phone = ''
          this.demand = ''
        })
      } else {
        this.$message.error('请填写完公司名称,姓名,联系电话!')
      }
    },
    scrollToTarget() {
      if (this.$route.query.vs) {
        // 获取目标元素的引用
        this.$nextTick(() => {
          const targetElement = this.$refs.contactUs
          const targetElements = document.getElementById('contactUs')
          console.log(targetElement, targetElements)
          // 使用原生 JavaScript 的 `scrollIntoView` 方法滚动到目标元素
          targetElement.scrollIntoView({ behavior: 'smooth' });
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bannerCls {
  background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  position: relative;
  height: 596px;
  background-image: url('../../assets/contactUsimg/banner.png');
.bannerCls_one{
      position: absolute;
      top: 155px;
      left: 284px;
      img{
        width: 530px;
        height: 310px;
      }
    }

}

.intelligentCreation{
  height: 400px;
  position: relative;
}


.jiesao {
  position: absolute;
  left: 229px;
  top: -50px;
  width: 1462px;
  height: 417px;
  background-image: url('../../assets/contactUsimg/miaos.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .jiesao_title {
    margin-left: 577px;
    margin-top: 67px;
    margin-bottom: 20px;
    color: #000000;
    font-weight: bold;
    font-size: 42px;
  }

  .jiesao_content {
    margin-left: 577px;
    line-height: 28px;
    color: #475272;
    font-size: 18px;
  }
}

.honorCls{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: linear-gradient(176deg, rgba(255,255,255,0) 0%, #F0F5FF 100%);
 .honorCls_one{
  font-weight: bold;
  font-size: 36px;
  color: #000000;
  text-align: center;
  margin-bottom: 20px;
 }
 .honorCls_two{
  margin-bottom: 20px;
 }
 .honorCls_img{
  width: 235px;
  height: 355px;
 }
 .honorCls_img_one{
  width: 300px;
  height: 355px;
 }
}

.aptitude{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 44px;
  margin-bottom: 64px;
  .aptitude_one{
    font-weight: bold;
    font-size: 36px;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
  }
  .aptitude_two{
    margin-bottom: 47px;
  }
  .aptitude_mar_right{
    margin-right: 40px;
  }
  .aptitude_img_size{
    width: 242px;
    height: 322px;
  }
}


.replenishment {
  font-size: 0;

  .from-location {
    position: relative;
  }

  .frombuu {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    bottom: 0;

    h4 {
      font-size: 36px;
      color: #fff;
    }

  }
}

table {
  width: 979px;
  height: 793px;
  background: #FFFFFF;
  border-radius: 8px;

  .mag-lef {
    margin-left: 43px;
  }
}

.inp {
  width: 894px;
  height: 40px;
  border: 1px solid #C9C9C9;
  border-radius: 4px;
  text-indent: 16px;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}

span {
  padding-right: 826px;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}

.unify {
  margin-top: 35px;
}

input {
  margin-top: 12px;
  outline-color: #9CAAB4;
}

.multiple {
  font-size: 17px;

  input {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-right: 13px;
    margin-left: 13px;
  }
}

button {
  width: 200px;
  height: 56px;
  background: #FF892E;
  opacity: 1;
  border-radius: 4px;
  margin-left: 347px;
  border: 1px solid #FF892E;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: 32px;
}

textarea {
  width: 894px;
  height: 103px;
  background: #F7F7F7;
  border: 1px solid #C9C9C9;
  border-radius: 4px;
  outline-color: #9CAAB4;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  text-indent: 16px;
  margin-top: 12px;
  resize: none;
  padding-top: 10px;
}

p {
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FF892E;
  margin-top: 20px;
}</style>
